/* .service_header {
  height: 79px;
  background: #1d1d1b;
} */

.service_title {
  font-weight: bold;
  font-size: 30px;
  color: #282828;
  margin: 8px 2%;
  padding-top: 15px;
}

.service_each_area {
  display: flex;
  border: 1px solid #ebebeb;
  margin: 8px 2%;
  padding: 10px 0px;
  padding-left: 2% px;
  width: 96%;
}

.service_each_img {
  width: 200px;
  height: 200px;
}

.service_each_title {
  font-weight: bold;
  font-size: 18px;
  color: #282828;
}

.service_each {
  width: 100%;
  padding-left: 20px;
}

.service_each_subtitle {
  font-size: 14px;
  color: #282828;
}

.service_each_title_bar {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  padding-top: 10px;
}

.service_each_time {
  font-size: 12px;
  background-color: #e5e7e9;
  color: #707171;
  padding: 7px 15px;
  border-radius: 2px;
  display: flex;
}

.service_each_time_icon {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  background: url(https://websiteasset.s3.ap-south-1.amazonaws.com/New+Website/components/Services/ServicesListDesktop/76D2D1C8-A85C-4C56-A53D-DE23B9C19F76.svg);
}

.service_each_subtitle {
  color: #707171;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 0px;
}

.service_each_datas {
  flex-wrap: wrap;
  display: flex;
  color: #707171;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: expanded;
}

.service_each_data {
  width: 400px;
  padding: 5px 0px;
  overflow: hidden;
}

.service_select {
  padding: 10px;
  margin: 10px;
  border: 1px solid #e73c33;
  color: #e73c33;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
}

.service_body_header_container {
  display: flex;
  position: relative;
  height: 90px;
  margin: 0px 15px;
  align-items: center;
  background: white;
  box-shadow: 0 0.25rem 0.3125rem 0 rgb(0 0 0 / 12%);
}

.service_body_header {
  position: sticky;
  top: 65px;
}

.service_a_a {
  min-width: 130px;
  max-width: 200px;
  margin: 0px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  height: 80px;
  border-radius: 10px;
  cursor: pointer;
}

.service_a_b {
  height: 40px;
  border-radius: 3px;
  margin-bottom: 5px;
  
}

.service_a_c {
  font-size: 12px;
  color: #0e0e0e;
  font-weight: 700;
}

.service_youtube {
  width: 75%;
  height: 600px;
  margin-left: 140px;
}

@media only screen and (max-width: 576px) {
  .service_body_header {
    top: 50px;
  }
  .service_body_header_container {
    height: 100px;
    margin: 0px 5px;
  }
  .service_a_a {
    min-width: 90px;
    max-width: 140px;
    background-color: #f3f3f3;
    height: 90px;
    
  }
  .service_a_b {
    height: 50px;
    margin-bottom: 5px;
  }
  .service_a_c {
    font-size: 10px;
    font-weight: 700;
    text-align: center;
  }
  .service_title {
    font-size: 13px;
  }
  .service_each_area {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .service_each_img {
    width: 40%;
    height: auto;
    border-radius: 4%;
  }
  .service_each_title {
    font-size: 13px;
  }
  .service_each_time {
    font-size: 11px;
  }
  .service_each_subtitle {
    font-size: 11px;
  }
  .service_each {
    padding-left: 11px;
  }
  .service_each_data {
    padding-left: 10px;
  }
}
