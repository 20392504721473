.selectCar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.headingCont {
	/* margin: 15px 0 !important; */
}

.compareCarDesc {
	width: 85%;
}

.compareCont {
	width: 13rem;
	border-radius: 2rem;
	height: 13rem;
	align-items: center;
	cursor: pointer;
	box-shadow: rgba(128, 128, 128, 0.575) 0px 5px 10px;
}

.selectButton {
	margin-top: 0.8rem;
	color: rgb(114, 114, 114);
}

.modal {
	display: block;
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}

.ChooseCarCont {
	margin: 1rem auto;
	padding: 2 5px 0;
	width: 96%;
	/* border: 1px solid rgb(201, 201, 201); */
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.modal-content-data {
	margin-top: 7rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hideModal {
	display: none;
	position: fixed;
	z-index: 1;
	padding-top: 100px;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	/* background-color: rgb(0, 0, 0); */
	background-color: rgba(0, 0, 0, 0.4);
}

.modal::-webkit-scrollbar {
	width: 0;
}

.modal-content {
	background-color: #fefefe;
	margin: 8rem auto;
	height: 60%;
	padding: 20px;
	border-radius: 1rem;
	border: 1px solid #888;
	max-width: 500px;
}

.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.compareCont {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 1.8rem;
}

@media only screen and (max-width: 576px) {
	.wrapperContainer h3 {
		font-size: 17px;
	}
	.styledButton {
		width: 3rem;
	}
}
