.hm2_a {
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
}

.hm2_b {
	width: calc(100vw / 3);
	height: calc(100vw / 3 - 24px);
	overflow: hidden;
	margin: 1.5px;
	cursor: pointer;
	position: relative;
	background-size: contain;
}

.hm2_c {
	position: relative;
	top: calc(100vw / 3);
	bottom: 0px;
	left: 0px;
	right: 0px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-color: #1f1f907a;
}

.hm2_b:hover .hm2_c {
	transition: 0.9s ease-in-out;
	top: 0px;
}

.hm2_b:hover {
	background-size: cover;
	transition: 0.5s ease-in-out;
	width: 50%;
}

.hm2_b:not(:hover) .hm2_c {
	transition: 0.3s ease-in-out;
	top: 500px;
}

.hm2_b:not(:hover) {
	background-size: cover;
	transition: 0.4s ease-in-out;
	width: calc(100% / 3);
}

.hm2_f {
	color: aliceblue;
	font-size: 20px;
	font-weight: bold;
	/* margin-left: 50px; */
}

.subtitle {
	color: rgb(195, 196, 198);
	font-size: 15px;
	margin-top: 20px;
	text-align: center;
	/* text-align: justify; */
	margin-left: 20px;
}

.hm2_d {
	display: flex;
	height: 120px;
	align-items: center;
}

.hm2_e {
	max-width: 200px;
	max-height: 100px;
}

/* /////////////////////// */

.hm2_ln_a {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(117, 117, 117, 0.612);
	position: fixed;
	top: 0px;
	bottom: 0px;
}
.hm2_ln_b {
	width: 100%;
	max-width: 800px;
	background-color: rgb(255, 242, 217);
	padding: 10px;
}
.hm2_ln_c {
	width: calc(100% - 40px);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
}
.hm2_ln_d {
	width: 120px;
}
.hm2_ln_e {
	width: 180px;
}
.hm2_ln_f {
	padding: 10px;
	background-image: linear-gradient(#565688, white);
}
.hm2_ln_g {
	color: white;
}
.hm2_ln_h {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr) ) ;
	width: 100%;
}
.hm2_ln_i {
	/* max-width: calc(100% / 3); */
	width: 100%;
	cursor: pointer;
}
.hm2_ln_j {
	width: 96%;
	height: auto;
	padding: 2%;
}
.hm2_ln_k {
	color: blue;
	font-weight: 500;
	font-size: 14px;
	padding-left: 3%;
	object-fit: cover;
}
.hm2_ln_l {
	width: 100%;
	cursor: pointer;
}

.hm2_ln_n {
	color: blue;
	font-weight: 500;
	font-size: 14px;
	padding-left: 3%;
}
.hm2_ln_o {
	width: 98%;
	padding: 1%;
	height: 200px;
	object-fit: cover;
}
.hm2_ln_p {
	display: flex;
	justify-content: center;
	padding: 8px;
	font-size: 9px;
	color: rgba(0, 0, 0, 0.735);
	font-weight: 500;
}

@media only screen and (max-width: 600px) {
	.hm2_c {
		display: none;
	}
}
