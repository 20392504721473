.hr-line {
	height: 0.2rem;
	width: 10rem;
	margin: 2rem auto;
	background-color: blue;
}
.main-bg {
	width: 96%;
	margin: 2%;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.left-cont {
	height: 100%;
	width: 50%;
	display: flex;
	/* align-items: center; */
	/* justify-content: center; */
	flex-direction: column;
}

.right-cont {
	height: 100%;
	/* width: 50%; */
	display: flex;
	align-items: center;
	justify-content: center;
	/* flex-wrap: wrap; */
	/* flex-direction: column; */
}

.img-cont {
	height: 10rem;
	width: 15rem;
	margin: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	box-shadow: 0 0.35rem 0.3125rem -0.25rem rgb(0 0 0 / 22%);
	/* border: 1px solid grey; */
	cursor: pointer;
}

.img-cont .img-div {
	margin: 0;
	padding: 0;
	height: 70%;
	width: 100%;
	border-radius: 5px 5px 0 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f2f2f2;
	border: 1px solid rgb(217, 212, 212);
}

.img {
	height: 7rem;
}

.img-content_title {
	width: 100%;
	height: 3.3rem;
	/* text-align: center; */
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid rgb(217, 212, 212);
	border-radius: 0 0 5px 5px;
	font-size: small;
	font-weight: 200;
}
.img-content-title h4 {
	color: rgb(104, 99, 99);
}

.heading-description {
	margin-top: 3.5rem;
	color: rgb(98, 96, 96);
	text-align: justify;
}

.heading-title {
	font-size: 1.7rem;
	font-weight: 600;
	text-align: center;
	background-image: linear-gradient(to right, rgb(39, 39, 39), rgb(10, 10, 10));
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	transition: all 0.3s;
}

.heading-title:hover {
	text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
	letter-spacing: 0.1rem;
	transform: translateY(-0.1rem);
}

/* for responsiveness */

@media only screen and (max-width: 768px) {
	.main-bg {
		width: 100vw;
		display: flex;
		flex-direction: column;
	}
	.left-cont {
		width: 100%;
	}
	.heading-description {
		margin-top: 1rem;
		/* font-size: 10px; */
		color: #2e2e2e;
		font-family: sans-serif;
		padding: 2px;
		font-size: 11px;
		letter-spacing: 1.2px;
	}
	.right-cont {
		width: 100%;
	}
}

@media only screen and (max-width: 576px) {
	.main-bg {
		width: 100vw;
		display: flex;
		flex-direction: column;
	}
	.left-cont {
		width: 100%;
		padding-right: 1rem;
	}
	.heading-description {
		margin-top: 1rem;
		/* font-size: 10px; */
		color: #2e2e2e;
		font-family: sans-serif;
		padding: 2px;
		font-size: 11px;
		letter-spacing: 1.2px;
	}
	.right-cont {
		width: 100%;
		overflow: scroll;
		justify-content: start;
		/* text-align: center; */
	}
	.right-cont::-webkit-scrollbar {
		width: 0;
	}
	h4 {
		height: 0.9rem;
	}
	.img-content_title {
		text-align: center;
	}
}

/* flip-box-card-style */

/* .card {
    color: #fff;
    perspective: 150rem;
    -moz-perspective: 150rem;
    border: none;
}
.card-side {
    height: 20rem;
    transition: all 0.8s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    backface-visibility: hidden;
    border-radius: 0.3rem;
}

.card-side-front {
    background-color: red;
}

.card-side-back {
    background-color: green;
    transform: rotateY(180deg);
}

.card:hover .card-side-front {
    transform: rotateY(-180deg);
}
.card:hover .card-side-back {
    transform: rotateY(0);
} */
