.wp1_a {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wp1_b {
  text-align: end;
  color: #aaaaaa;
  font-weight: 500;
}
