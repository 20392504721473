.all1_hm_title {
  font-size: 15px;
  padding: 14px 8px;
  font-weight: bold;
}

@media only screen and (max-width: 576px) {
  .all1_hm_title {
    font-size: 12px;
    padding: 8px 6px;
  }
}
