.cr_1c {
	display: flex;
	justify-content: center;
	width: 100%;
}

.cr_1d {
	max-width: 1000px;
	width: calc(100% - 40px);
}

.cr_1e {
	font-size: 23px;
	font-weight: 700;
	padding: 5px 0;
	color: #484848;
	margin-top: 30px;
}

.cr_1g {
	font-size: 13px;
	color: #474747;
	display: flex;
	position: sticky;
	top: 63px;
	background-color: white;
	box-shadow: 2px 8px 6px rgba(0, 0, 0, 0.103);
	padding: 0px 2%;
	margin: 5px 0px;
	font-weight: 500;
}

.cr_1g_a {
	padding: 10px 25px 10px 0px;
	cursor: pointer;
}

.cr_1h {
	width: 100%;
	border: 1.5px solid #e1e1e1;
	border-radius: 6px;
	display: flex;
}

.cr_1i {
	width: 100%;
	max-width: 480px;
}

.cr_1j {
	height: 300px;
	width: 450px;
	border-top-left-radius: 6px;
	background-color: wheat;
}

.cr_1k {
	margin: 3px;
	object-fit: cover;
	height: 30px;
	width: 45px;
	border: 2px solid white;
}

.cr_1m {
	margin-left: 20px;
	width: 100%;
}

.cr_1n {
	border-left: 1.5px solid #e1e1e1;
	border-bottom: 1.5px solid #e1e1e1;
	width: 100%;
	max-width: 250px;
	padding: 16px;
}

.cr_1p {
	color: #6f6f6f;
	font-size: 13px;
}

.cr_1q {
	font-size: 13px;
	padding-top: 10px;
	font-weight: 600;
	color: #484848;
}

.cr_1s {
	display: flex;
	justify-content: space-between;
}

.cr_1x {
	font-size: 22px;
	font-weight: 600;
	color: #484848;
	padding: 22px 8px 9px 12px;
}

.cr_1y {
	color: #6f6f6f;
	font-size: 14px;
	padding: 0px 12px 12px 12px;
}

.cr_1y_a {
	color: #477ed6;
	font-size: 14px;
	padding: 0px 12px 12px 12px;
	cursor: pointer;
}

.cr_1z {
	margin-right: 15px;
	background-color: #fbfbfb;
	padding: 10px;
	padding-left: 20px;
}

.cr_1u {
	display: flex;
	justify-content: space-between;
}

.cr_1aa {
	font-size: 15px;
	color: #484848;
}

.cr_1ab {
	font-size: 12px;
	font-weight: 600;
	color: #0288d1;
	padding-top: 4px;
	cursor: pointer;
}

.cr_1ac {
	border: 1px solid #00afa0;
	color: #00afa0;
	border-radius: 3px;
	padding: 8px 23px;
	font-size: 12px;
	font-weight: 600;
	/* height: 14px; */
	cursor: pointer;
}

.cr_1ad {
	font-size: 15px;
	color: #0288d1;
	padding: 18px 0px;
	cursor: pointer;
}

.cr_1f {
	display: flex;
	padding: 15px 0px;
}

.cr_1f_a {
	height: 14px;
	color: #00afa0;
	margin-right: 10px;
}

.cr_1ae {
	background-color: #e53012;
	padding: 13px;
	color: white;
	font-weight: bold;
	font-size: 18px;
	border-radius: 3px;
	width: 280px;
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.cr_1af {
	border: 1.5px solid #e1e1e1;
	border-radius: 3px;
	margin: 10px 0px;
	/* width: 100%; */
	padding: 14px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.cr_1ag {
	min-width: 20px;
	width: 20px;
	height: 20px;
	margin: 0px 10px;
	margin-left: 10px;
	font-size: 13px;
	background-color: #00afa0;
	border: 2px solid white;
}

.cr_1ah {
	font-size: 14px;
	white-space: nowrap;
	width: auto;
	margin-right: 10px;
	overflow: hidden;
}

.cr_sp_b {
	width: 100%;
	margin: 6px 0px;
	display: flex;
	flex-wrap: wrap;
	overflow: auto;
}

.cr_sp_c {
	background-color: #fbfbfb;
	border: 1px solid #efefef;
	padding: 10px;
	width: calc(50% - 24px);
}

.cr_sp_d {
	font-size: 12px;
	padding: 3px 0px;
	color: #aaaaaa;
}

.cr_sp_e {
	font-weight: bold;
	padding: 3px 0px;
	color: #484848;
}

.cr_sm_b {
	background-color: #fbfbfb;
	border: 1px solid #efefef;
	border-radius: 5px;
	padding: 10px 20px;
}

.cr_sm_d {
	font-weight: bold;
	padding: 10px 0px;
	color: #6f6f6f;
}

.cr_sm_e {
	font-size: 15px;
	padding-bottom: 3px;
	color: #6f6f6f;
}

.cr_1_a_a {
	display: flex;
	max-width: 500px;
	flex-wrap: wrap;
	overflow: auto;
	padding: 5px;
}

.cr_1_a_b {
	font-weight: 500;
	padding: 10px;
	color: #a0a0a0;
	font-size: 14px;
	border-radius: 3px;
	border: 1px solid rgb(207, 207, 207);
	white-space: nowrap;
}

.cr_1_a_c {
	border-color: #e0e0e0;
	border-radius: 3px;
	margin: 0px 10px;
	width: 50px;
	color: #b8b8b8;
	width: 100%;
}

.cr_1q_a {
	font-size: 13px;
	color: rgb(71, 71, 71);
	padding: 3px;
	border-width: 0.5px;
	border-radius: 3px;
	border-color: #a0a0a0a1;
	width: 100%;
}

.cr_1q_a :focus {
	background-color: aqua;
}

@media only screen and (max-width: 576px) {
	.cr_1e {
		font-size: 14px;
		padding: 10px 10px;
		margin-top: 5px;
	}
	.cr_1g {
		display: none;
	}
	.cr_1f {
		padding: 0px 10px;
		width: 80px;
	}
	.cr_1h {
		flex-wrap: wrap;
	}
	.cr_1j {
		width: 100%;
		height: auto;
	}
	.cr_1p {
		font-size: 11px;
	}
	.cr_1n {
		padding: 10px;
	}
	.cr_1x {
		font-size: 13px;
		padding: 5px 4px 5px 2px;
	}
	.cr_1y {
		font-size: 12px;
		padding: 0px 12px 5px 2px;
	}
	.cr_1y_a {
		font-size: 12px;
		padding: 0px 12px 5px 2px;
	}
	.cr_1k {
		margin: 1px;
		height: 20px;
		width: 30px;
	}
	.cr_1ag {
		width: 15px;
		height: 15px;
	}
	.cr_1ah {
		font-size: 11px;
	}
	.cr_1aa {
		font-size: 10px;
	}
	.cr_1ah {
		font-size: 10px;
	}
	.cr_1ac {
		padding: 6px 18px;
		font-size: 11px;
	}
	.cr_1ad {
		font-size: 12px;
		padding: 12px 0px;
	}
	.cr_1ae {
		width: calc(100% - 20px);
		font-size: 13px;
		padding: 12px 0px;
		margin-bottom: 15px;
	}
	.cr_sp_b {
		margin: 5px 0;
	}
	.cr_sp_c {
		padding: 5px;
	}
	.cr_sp_d {
		font-size: 10px;
	}
	.cr_sp_e {
		font-size: 11.5px;
	}
	.cr_sm_b {
		padding: 6px 12px;
	}
	.cr_sm_d {
		font-size: 12px;
	}
	.cr_sm_e {
		font-size: 11.5px;
	}
}
