.ac1_a {
  height: 4px;
  background-color: rgb(91, 190, 149);
}

.ac1_b {
  display: flex;
  width: 100%;
  overflow: auto;
  align-items: center;
}

.ac1_c {
  color: #aaa;
  padding: 8px;
}

.ac1_d {
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  width: 330px;
  margin: 7px;
  background-color: #f9f9f9;
  color: #7b7b7b;
  cursor: pointer;
}

.ac1_e {
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 220px;
}

.ac1_f {
  color: #8d7b76;
  padding: 8px 12px;
}

.ac1_g {
  cursor: pointer;
}

.ac1_h {
  display: flex;
  flex-wrap: wrap;
}

.ac1_i {
  width: 330px;
  height: 220px;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 4px;
}

.ac1_j {
  border-top-left-radius: 5px;
  height: 100%;
  width: 100%;
  border-top-right-radius: 4px;
}

.ac1_k {
  height: 20px;
  overflow: hidden;
  max-width: calc(50vw - 55px);
}

@media only screen and (max-width: 576px) {
  .ac1_g {
    max-width: calc(100% / 3.6);
  }
  .ac1_c {
    font-size: 10.5px;
    white-space: nowrap;
    overflow: hidden;
  }
  .ac1_a {
    height: 2px;
  }
  .ac1_h {
    flex-wrap: wrap;
  }

  .ac1_d {
    width: calc(50% - 20px);
  }
  .ac1_e {
    width: 100%;
    height: calc(30vw);
  }
  .ac1_f {
    font-size: 10.5px;
    padding: 6px 10px;
  }
  .ac1_i {
    width: calc(50vw - 30px);
    height: calc(30vw);
  }
  .ac1_k {
    font-size: 12px;
    height: 40px;
    font-weight: 600;
    /* color: black; */
  }
}
