.hb_1a {
	margin: auto;
	width: 96%;
}
.hb_1b {
	margin: 0.3rem 0;
	display: flex;
	justify-content: space-between;
}

.hb_1b_a {
	font-size: 30px;
	color: #282828;
	font-weight: 600;
}

/* CSS */
.hb_1b_b {
	align-items: center;
	appearance: none;
	background-image: radial-gradient(
		100% 100% at 100% 0,
		#5adaff 0,
		#5468ff 100%
	);
	border: 0;
	border-radius: 6px;
	box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px, rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	display: inline-flex;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	height: 40px;
	justify-content: center;
	line-height: 1;
	list-style: none;
	overflow: hidden;
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	text-align: left;
	text-decoration: none;
	transition: box-shadow 0.15s, transform 0.15s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	will-change: box-shadow, transform;
	font-size: 15px;
}

.hb_1b_b:focus {
	box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.hb_1b_b:hover {
	box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
		rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
	transform: translateY(-2px);
}

.hb_1b_b:active {
	box-shadow: #3c4fe0 0 3px 7px inset;
	transform: translateY(2px);
}

.hb_1c {
	font-size: 16px;
	color: #4a4a4a;
	padding: 3px;
	text-align: justify;
}

.hb_1d {
	background-color: #2f378e;
	height: 3px;
	width: 120px;
	margin-bottom: 10px;
	margin: 2rem 0;
	border-radius: 1.5px;
}

.hb_1b1 {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	flex-direction: column;
}

.hb_1b2 {
	display: flex;
	width: 100%;
	overflow: auto;
}

.hb_1b3 {
	color: #aaaaaa;
	padding: 8px;
	display: flex;
	align-items: center;
}

.hb_1b2a {
	padding: 0px 4px;
	cursor: pointer;
}

.hb_1b2b {
	height: 0.5px;
	background-color: #d8d8d8;
}

.hb_1b3a {
	height: 3px;
	background-color: #00afa0;
}

.hb_8a {
	display: flex;
	flex-wrap: wrap;
}

.hb_8c {
	border-radius: 4px;
	border: 1px solid #e1e1e1;
	margin: 7px;
	background-color: #f9f9f9;
	color: #7b7b7b;
	cursor: pointer;
}

.hb_8d {
	width: 330px;
	height: 220px;
	background-color: white;
	border-top-left-radius: 5px;
	border-top-right-radius: 4px;
}

.hb_8e {
	border-top-left-radius: 5px;
	height: 100%;
	width: 100%;
	border-top-right-radius: 4px;
}

.hb_8f {
	padding: 15px 15px;
}

.hb_8g {
	font-weight: bold;
	padding-top: 10px;
	padding-bottom: 5px;
	color: rgb(48, 42, 42);
}

.hb_8h {
	font-size: 12px;
	padding: 5px 0px;
}

.hb_8i {
	background-image: linear-gradient(#0dccea, #0d70ea);
	border: 0;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	font-family: Montserrat, sans-serif;
	font-size: 0.9em;
	margin: 5px;
	padding: 10px 15px;
	text-align: center;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

/* .hb_8i {
    padding: 6px 10px;
    background-color: #00acee;
    color: white;
    box-shadow: 2px 2px 1px 0px rgb(168, 168, 168);
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
}
*/
.hb_8i:hover {
	box-shadow: 1px 1px 1px 0px rgb(255, 255, 255);
}

.hb_8i_1 {
	background-image: linear-gradient(#fd3672, #ea0d48);
	border: 0;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.3) 0 5px 15px;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	font-family: Montserrat, sans-serif;
	font-size: 0.9em;
	margin: 5px;
	padding: 10px 15px;
	text-align: center;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.hb_8i_1:hover {
	box-shadow: 1px 1px 1px 0px rgb(255, 255, 255);
}
/* .hb_8i:hover{
  background-color: #4fa86c;
  border-color: #618cb4;
  color: #e0e7ed;
} */
/* .hb_8i_1 {
    padding: 6px 10px;
    background-color: #0ac300;
    color: white;
    box-shadow: 2px 2px 1px 0px rgb(149, 149, 149);
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
}
.hb_8i_1:hover{
  background-color: #4fa86c;
  border-color: #618cb4;
  color: #e0e7ed;
} */

.hb_8j {
	height: 20px;
	overflow: hidden;
	max-width: 300px;
}

.hb_2aa1 {
	display: flex;
	align-items: center;
	max-width: 900;
}

@media only screen and (max-width: 576px) {
	.hb_2aa1 {
		display: flex;
		flex-direction: column;
	}
	.hb_1d {
		height: 2px;
		width: 80px;
		margin-bottom: 8px;
		margin-top: 12px;
		border-radius: 1px;
	}
	.hb_1b_a {
		font-size: 17px;
	}
	.hb_1c {
		padding: 2px;
		font-size: 11px;
		letter-spacing: 1px;
	}
	.hb_1b_b {
		align-items: center;
		appearance: none;
		background-image: radial-gradient(
			100% 100% at 100% 0,
			#5adaff 0,
			#5468ff 100%
		);
		border: 0;
		border-radius: 6px;
		box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
			rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
			rgba(58, 65, 111, 0.5) 0 -3px 0 inset;
		box-sizing: border-box;
		color: #fff;
		cursor: pointer;
		display: inline-flex;
		font-family: 'JetBrains Mono', monospace;
		height: 35px;
		justify-content: center;
		line-height: 1;
		list-style: none;
		overflow: hidden;
		padding-left: 16px;
		padding-right: 16px;
		position: relative;
		text-align: left;
		text-decoration: none;
		transition: box-shadow 0.15s, transform 0.15s;
		user-select: none;
		-webkit-user-select: none;
		touch-action: manipulation;
		white-space: nowrap;
		will-change: box-shadow, transform;
		font-size: 13px;
	}

	.hb_1b_b:focus {
		box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
			rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
	}

	.hb_1b_b:hover {
		box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
			rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
		transform: translateY(-2px);
	}

	.hb_1b_b:active {
		box-shadow: #3c4fe0 0 3px 7px inset;
		transform: translateY(2px);
	}
	.hb_1b3 {
		font-size: 11px;
		padding: 5px;
	}
	.hb_1b3a {
		height: 1.5px;
	}
	/* Car */
	.hb_8a {
		flex-wrap: nowrap;
		width: 100%;
		overflow: auto;
	}
	.hb_8a::-webkit-scrollbar {
		width: 0;
	}
	.hb_8d {
		width: 195px;
		height: 130px;
	}
	.hb_8j {
		font-size: 13px;
		height: 13px;
		max-width: 160px;
	}
	.hb_8g {
		font-size: 13px;
		line-height: 65%;
	}
	.hb_8h {
		font-size: 10px;
		line-height: 65%;
	}
	/* .hb_8i {
        font-size: 10px;
        line-height: 65%;
    } */
	.hb_8i_1 {
		font-size: 10px;
		line-height: 65%;
		margin-top: 10px;
	}

	/* summer section */

	/*  */
	/*  */
	/*  */
}
