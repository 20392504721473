.hm1_a {
	height: 35vw;
}
.hm1_b {
	position: absolute;
	width: calc(100vw - 8px);
}
.hm1_c {
	position: relative;
	top: 4px;
	background-color: white;
	padding: 4px;
}
.hm1_d {
	height: 35vw;
	min-width: 100%;
	object-fit: cover;
}
.hm1_e {
	width: 100%;
	height: 30px;
}
.hm1_f {
	padding: 5px 10px;
	font-size: 12px;
	cursor: pointer;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.753);
}
.hm1_f:hover {
	background-color: rgba(0, 0, 0, 0.301);
}
.hm1_hd_a {
	display: flex;
	position: sticky;
	top: 0px;
	justify-content: space-between;
	align-items: center;
	padding: 6px 20px;
	background-color: white;
	box-shadow: rgba(214, 214, 214, 0.623) 0px 3px 6px;
}
.home_base_logo_area {
	cursor: pointer;
	display: flex;
	align-items: center;
}
.home_base_logo {
	-o-object-fit: contain;
	object-fit: contain;
	width: 120px;
	border-radius: 5px;
}
.header_container_menu {
	display: flex;
	align-items: center;
}
.location_text {
	cursor: pointer;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.5);
	padding-left: 15px;
	font-size: small;
	border: none;
	outline: none;
}
.header_text {
	font-weight: 500;
	color: #1e5d8f;
	font-size: 16px;
	/* padding: 0px 14px; */
	margin-right: 30px;
	cursor: pointer;
	padding: 3px 5px;
	border: 0.5px solid #1e5d8f;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
}
.header_text:hover {
	color: rgb(95, 147, 196);
}
.headphone_icon {
	width: 16px;
	margin-right: 4px;
}
.logo_icon1 {
	width: 160px;
	margin-top: 5px;
}
.car_setting_popup {
	padding-top: 15vw;
	position: relative;
}
.car_setting_popup_body {
	width: 100%;
	max-width: 700px;
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0px 0px 20px rgb(0 0 0 / 40%);
}
.car_setting_popup_type_input {
	height: 41px;
	width: 150px;
	padding: 5px 14px;
	border: none;
	font-size: 15px;
	background-color: #e1e1e1;
	border-bottom-left-radius: 3px;
	border-top-left-radius: 3px;
}
.car_setting_popup_car_input {
	width: 100%;
	height: 30px;
	padding: 5px 14px;
	border: none;
	font-size: 15px;
}
.car_setting_popup_type_input:focus {
	outline-width: 0px;
}
.car_setting_popup_car_input:focus {
	outline-width: 0px;
}
.car_setting_popup_button {
	cursor: pointer;
	background-color: #2f378e;
	color: #fff;
	font-size: 15px;
	font-weight: 600;
	border-radius: 2px;
	margin: 2px;
	text-align: center;
	padding: 10px 20px;
	white-space: nowrap;
	border: none;
}
.car_setting_popup_title {
	font-size: 28px;
	font-family: 'Gilroy', sans-serif;
	font-weight: 500;
	padding-bottom: 15px;
	color: #fff;
}
.car_setting_popup_subtitle {
	font-size: 15px;
	font-family: 'Gilroy', sans-serif;
	color: #fff;
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	max-width: 700px;
	width: 100%;
}
.footer_top {
	background-color: #071c2c;
	color: #fff;
	display: flex;
	position: relative;
}
.footer_top_each {
	width: 100%;
	padding: 10px 40px;
	font-weight: 500;
	font-size: 12px;
	letter-spacing: 1px;
}
.footer_top_title {
	padding: 18px 0px;
}
.footer_top_button {
	padding: 6px 0px;
	cursor: pointer;
}
.footer_down {
	background-color: #282828;
	color: #fff;
	padding: 30px;
	display: flex;
	position: relative;
}

.carousel .slide{
	min-width: fit-content !important;
}
@media only screen and (max-width: 576px) {
	.footer_top {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.footer_down {
		display: flex;
		flex-wrap: wrap;
	}
}
.footer_address {
	font-size: 13px;
	display: flex;
	width: 322px;
	line-height: 25px;
	padding-top: 15px;
}
.footer_contact {
	width: 100%;
	padding-top: 10px;
}
.footer_applink {
	padding: 0px 60px;
}
.footer_copyright {
	background-color: #282828;
	color: #fff;
	text-align: center;
	padding-bottom: 10px;
	font-size: 12px;
	position: relative;
}
.footer_contact_each {
	padding: 10px;
	display: flex;
	justify-content: center;
}
.footer_contact_title {
	font-size: 12px;
	width: 180px;
	font-weight: bold;
}

.footer_contact_data {
	width: 180px;
	font-size: 14px;
}

.footer_socialmedia_icon {
	padding: 20px;
	display: flex;
}

.footer_contact_icon_fb {
	margin-left: 25px;
	width: 11px;
	height: 20px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -262px -46px;
}

.footer_contact_icon_twitter {
	width: 21px;
	height: 18px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -160px -130px;
	margin-left: 25px;
}

.footer_contact_icon_instagram {
	margin-left: 25px;
	width: 19px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -50px -196px;
}

.footer_contact_icon_whatsapp {
	width: 20px;
	height: 20px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -10px -196px;
	margin-left: 25px;
}

.footer_contact_icon_youtube {
	width: 26px;
	margin-left: 25px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -114px -130px;
}

.footer_contact_icon_email {
	width: 20px;
	height: 16px;
	cursor: pointer;
	background: url(../asset/small_icons.png) -201px -130px;
	margin-right: 10px;
}

.footer_contact_icon_phone {
	width: 20px;
	height: 16px;
	background: url(../asset/small_icons.png) -129px -196px;
	margin-right: 10px;
}

.footer_contact_icon_callender {
	width: 20px;
	height: 16px;
	background: url(../asset/small_icons.png) -262px -10px;
	margin-right: 10px;
}

.footer_contact_icon_clock {
	width: 20px;
	height: 16px;
	background: url(../asset/small_icons.png) -262px -10px;
	margin-right: 10px;
}

.footer_contact_icon_clock {
	width: 16px;
	height: 16px;
	background: url(../asset/small_icons.png) -165px -196px;
	margin-right: 10px;
}

.mylocation_popup_screen {
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 10, 10, 0.15);
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
}

.mylocation_popup {
	max-width: 900px;
	height: 80%;
	background-color: #fafafa;
	padding: 40px;
}

.mylocation_popup_title {
	font-size: 28px;
	font-weight: bold;
	color: #071c2c;
}

.mylocation_popup_subtitle {
	font-size: 14px;
	font-weight: 500;
	color: gray;
	margin-bottom: 20px;
}
.mylocation_popup_subtitle2 {
	font-size: 16px;
	font-weight: 600;
	color: gray;
	margin-bottom: 20px;
}

.mylocation_popup_body {
	padding: 10px 0px;
	flex-wrap: wrap;
	display: flex;
	height: 35%;
	overflow: auto;
	justify-content: space-between;
	scrollbar-width: thin;
}
.mylocation_popup_body::-webkit-scrollbar {
	height: 12px;
	width: 8px;
	background: #fff;
}

.mylocation_popup_body::-webkit-scrollbar-thumb {
	background: gray;
	border-radius: 1ex;
	box-shadow: 1px 0px 0px #fff;
}

.mylocation_popup_body::-webkit-scrollbar-corner {
	background: #fff;
}

.mylocation_each {
	padding: 20px 40px;
	text-align: center;
	font-size: 14px;
	width: 80px;
	color: #071c2c;
	cursor: pointer;
}

.hm1_mo_hd_a {
	background-color: white;
	height: 50px;
	max-width: calc(100% - 24px);
	display: none;
	position: sticky;
	top: 0px;
	padding: 0px 12px;
	align-items: center;
	justify-content: space-between;
}
.dzireEnquiry,.evitaEnquiry {
	cursor: pointer;
  z-index: 9999;
  position: absolute;
  bottom: 40px;
  left: 52%;
  transform: translateX(-50%);
  padding: 15px 25px;
  background-image: linear-gradient(#0dccea, #0d70ea);
  color: #fff;
  border: none;
  border-radius: 5px;
	font-size:15px
}

.hm1_mo_hd_b {
	object-fit: contain;
	height: 40px;
}

.footer_logo {
	height: 60px;
}

@media only screen and (max-width: 576px) {

	.dzireEnquiry,.evitaEnquiry {
		cursor: pointer;
		z-index: 9999;
		position: absolute;
		bottom: 40px;
		left: 52%;
		transform: translateX(-50%);
		padding: 10px 15px;
		background-image: linear-gradient(#0dccea, #0d70ea);
		color: #fff;
		border: none;
		border-radius: 5px;
		font-size:15px
	}
	.home_base_logo {
		width: 100px;
	}
	.header_container_menu {
		display: none;
	}
	.hm1_hd_a {
		display: none;
	}
	.hm1_mo_hd_a {
		display: flex;
		width: 100%;
	}
	.car_setting_popup {
		display: none;
	}

	/* footer1 */
	.footer_top_each {
		padding: 8px 30px;
	}
	.footer_top_button {
		padding: 4px 0px;
	}
	.footer_top_title {
		padding: 11px 0px;
	}
	.footer_contact_data {
		max-width: 200px;
		overflow: auto;
	}
	/*  */
	.hm1_b {
		width: 100%;
	}
	.header_text {
		font-size: 10px;
		padding: 3px 5px;
		border-width: 0.5px;
		margin-right: 5px;
	}
	.headphone_icon {
		width: 11px;
		margin-right: 2px;
	}
	.footer_logo {
		height: 40px;
		margin-right: calc(50% - 133px);
	}
	.footer_logo_add {
		display: flex;
		justify-content: space-between;
	}
	.footer_address {
		width: 50%;
	}

	.hm1_a {
		height: 66.6vw;
	}
	.hm1_d {
		height: 66.6vw;
	}
	.car_setting_popup_subtitle {
		font-size: 12px;
	}

	.logo_icon1 {
		width: 30px;
		margin-left: 10px;
	}
}
