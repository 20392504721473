body {
	margin: 0;
	/* font-family: Verdana, Tahoma, "Trebuchet MS", "DejuVu Sans",
    "Bitstream Vera Sans", sans-serif; */

	/* font-family: sans-serif, "Helvetica Neue", "Lucida Grande", Arial; */
	font-family: Gilroy, sans-serif;
	font-stretch: normal;
	scrollbar-width: thin;
	/* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body::-webkit-scrollbar {
	height: 12px;
	width: 8px;
	background: #fff;
}

body::-webkit-scrollbar-thumb {
	background: gray;
	-webkit-border-radius: 1ex;
	-webkit-box-shadow: 1px 0px 0px #fff;
}

body::-webkit-scrollbar-corner {
	background: #fff;
}

.row {
	display: flex;
}

.center {
	display: flex;
	justify-content: center;
}
