* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.mainHeading {
	margin-top: 5px;
	color: #484848;
	font-size: 1.3rem;
	padding: 10px 0;
}

.mainContainer {
	width: 100%;
	height: auto;
	margin: 10px 0;
	/* border: 1px solid rgb(183, 180, 180); */
	border-radius: 4px;
	/* border: 1px solid #efefef; */

	display: flex;
	/* flex-direction: column; */
	padding: 0.5rem;
	overflow-x: scroll;
	/* scrollbar-width: none; */

	/* align-items: center;
	justify-content: center; */
	gap: 1rem;
	/* background-color: rgb(247, 242, 242); */
}

.mainContainer::-webkit-scrollbar {
	width: 0;
}
.versionContainer {
	display: flex;
	flex-direction: column;
	border-radius: 0.5rem;
	/* border: 1px solid rgb(182, 179, 179); */
	padding: 5px;
	cursor: pointer;
}

.versionContainer:hover {
	box-shadow: 0 0 0.9rem 0.1rem rgb(154, 150, 150);
}
.imgCont {
	display: flex;
	align-items: center;
	justify-content: center;
}
.imgCont img {
	width: 200px;
	/* box-shadow: 0 0 0.9rem 0.1rem rgb(97, 95, 95); */
	object-fit: contain;
	border-radius: 5px;
}

.title {
	font-size: 15px;
	margin-top: 5px;
	font-weight: 900;
	color: rgb(46, 45, 45);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;

}
.price {
	margin-top: 5px;
	font-weight: 300;
	font-size: 0.9rem;
}
.Ex_price {
	margin-top: 5px;
	font-weight: 200;
	font-size: 0.7rem;
	color: rgb(130, 129, 129);
}

@media only screen and (max-width: 600px) {
	.mainHeading {
		font-size: 14px;
		color: #181818;
	}
	.title {
		font-size: 12px;
	}
	.price {
		font-size: 11px;
	}
}
