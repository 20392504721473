.hm3_Container {
  background-color: #cbcbcb;
  background-size: cover;
  height: 420px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 1%;
  background-image: url(https://bajajapi.escvpn.com/upload/banner/form1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hm3_Container2 {
  background-color: #cbcbcb;
  background-size: cover;
  height: 420px;
  width: 100%;
  max-width: 1000px;
  margin: 20px 1%;
  background-position-x: center;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}

.hm3_left-div {
  max-width: 300px;
  margin: 5%;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 3px #aaa;
  border-radius: 2px;
  align-items: flex-end;
  margin-left: 150px;
}
.hm3_1_left-div {
  max-width: 300px;
  margin: 5%;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 3px #aaa;
  border-radius: 2px;
  align-items: flex-end;
  margin-left: 150px;
}

.hm3_input_tab {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px;
  box-sizing: border-box;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 2px;
  font-family: sans-serif;
  outline: none;
}

.hm3_input_tab :focus {
  background-color: aqua;
}

.hm3_btn {
  padding: 8px;
  text-align: center;
  color: white;
  border-width: 0px;
  margin: 10px;
  border-radius: 2px;
  background-color: #1b65b1;
  cursor: pointer;
  width: calc(100% - 20px);
}

.hm3_sf_a {
  display: none;
}

.hm3_sf_b {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.904);
}

.hm3_sf_c {
  width: 22px;
  margin-bottom: 4px;
}

.hm3_sf_d{
  width: 500px;
}

@media only screen and (max-width: 576px) {
  .hm3_Container2 {
    padding: 8px 6px;
    align-items: center;
    justify-content: center;
    height: 320px;
  }
  .hm3_left-div {
    width: 350px;
    margin-left: 0px;
  }
  .hm3_1_left-div {
    width: 350px;
    margin-left: -250px;
  }
  .hm3_input_tab {
    width: calc(100% - 12px);
    padding: 6px;
    margin: 6px;
    font-size: 12px;
  }
  .hm3_btn {
    font-size: 12px;
    margin: 6px;
    width: calc(100% - 12px);
  }

  .hm3_sf_a {
    display: flex;
    background-color: #2d3393;
    position: fixed;
    bottom: 0px;
    height: 60px;
    width: 100vw;
    box-shadow: rgba(128, 128, 128, 0.692) 1px 1px 3px 2px;
    align-items: center;
  }
  .hm3_sf_d{
    width: 110px;
  }
}
