.cu1_a_a {
  display: flex;
  justify-content: center;
}

.cu1_a_b {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
}

.cu1_a_c {
  display: flex;
}

.cu1_a_d {
  padding: 0px 8px;
  cursor: pointer;
}

.cu1_a_e {
  color: #aaaaaa;
  padding: 8px;
}

.cu1_a_f {
  height: 3px;
  background-color: #00afa0;
}

.cu1_a_g {
  height: 0.5px;
  background-color: #d8d8d8;
  margin: 1px;
}

.cu1_b_a {
  display: flex;
  justify-content: center;
}

.cu1_b_lc1_a {
  max-width: 1000px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  width: calc(100% - 20px);
  margin: 10px 1%;
  display: flex;
  justify-self: center;
  flex-wrap: wrap;
  overflow: auto;
}

.cu1_b_lc1_ab {
  width: calc(100% - 20px);
  max-width: 400px;
  max-height: 280px;
  margin: 10px;
  border-radius: 10px;
}

.cu1_b_lc1_ac {
  width: 100%;
  max-width: 500px;
}

.cu1_b_lc1_acab {
  width: 30px;
  padding-bottom: 5px;
}

.cu1_b_lc1_acac {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  width: 100%;
}

.cu1_b_lc1_acaca {
  color: #0c9ca9;
  font-size: 13px;
  cursor: pointer;
  white-space: nowrap;
  border: 1.5px solid #0c9ca9;
  padding: 0px 10px;
  margin: 2px 0px;
  border-radius: 4px;
  width: 150px;
  height: 30px;
  text-align: center;
  background-color: blue;
  color: white;
  
}

.cu1_b_lc1_aca {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.cu1_b_lc1_acaa {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: #0c9ca9;
  white-space: nowrap;
}

.cu1_b_lc1_acba {
  width: 100%;
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
}

.cu1_b_lc1_acbb {
  color: gray;
  padding: 0px 20px;
}

.cu1_b_lc1_acbba {
  padding: 0px 20px;
  padding-top: 4px;
  font-size: 15px;
  color: #0c9ca9;
}
