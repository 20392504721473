.cr2_a {
	box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
	max-width: 1000px;
	width: 100%;
	padding: 10px 0px;
	margin: 0% 0px;
}

.cr2_ac {
	display: flex;
	justify-content: space-between;
	padding: 10px 20px;
}

.cr2_aca {
	width: 30%;
	font-size: 12px;
	color: rgb(153, 153, 153);
}

.cr2_aa {
	height: 1px;
	width: calc(100%-40px);
	background-color: rgb(230, 230, 230);
	margin: 5px 20px;
}

.cr2_ada {
	width: 100%;
	min-width: 40%;
	font-size: 14px;
	color: rgb(131, 131, 131);
}
.cr2_adb {
	width: 100%;
	min-width: 30%;
	font-size: 14px;
	color: rgb(44, 44, 44);
	font-weight: bold;
}
.cr2_adc {
	width: 100%;
	min-width: 30%;
	font-size: 14px;
	color: rgb(131, 131, 131);
}

.cr2_adba {
	font-size: 12px;
	color: rgb(131, 131, 131);
	font-weight: normal;
}

.cr2_adca {
	color: rgb(5, 155, 255);
	cursor: pointer;
}

/* /////////////////////////////////////////////////////////////////////////////// */

.cr2_pd_a {
	padding: 10px;
	margin-bottom: 9px;
	border-radius: 4px;
	border: 1px solid rgb(145, 138, 138, 0.2);
}

.cr2_pd_b {
	padding: 10px 0px;
	display: flex;
	flex-direction: column-reverse;
}

.cr2_pd_c {
	height: 20rem;
	width: 50%;
}

.d_cont {
	display: flex;
	/* flex-direction: column-reverse; */
}

.cr2_pd_d {
	padding-left: 20px;
}

.cr2_pd_e {
	font-size: 20px;
	font-weight: 700;
	color: #484848;
	margin: 0;
}

.cr2_pd_f {
	padding-top: 10px;
	color: gray;

	/* margin-top: 2rem; */
	/* text-indent: 1rem; */
	display: flex;
	align-items: center;
	/* height: 100%; */
	justify-content: center;
	font-size: 15px;
	/* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New'; */
}
.cr2_pd_g img {
	box-shadow: 0.3rem 0.5rem 0.3125rem -0.25rem rgb(0 0 0 / 22%);
}
.cr2_pd_g {
	margin: -16px;
	padding: 0;
	margin-top: 1rem;
	display: flex;
}

.cr2_pd_h {
	width: 50px;
	height: 30px;
	object-fit: cover;
	margin: 3px 15px;
}

@media only screen and (max-width: 767px) {
	.d_cont {
		margin-top: -1px;
		display: flex;
		flex-direction: column-reverse;
		padding: 0;
		justify-content: space-between;
	}
	.cr_1e {
		color: black;
		font-size: 14px;
		padding-left: 1px;
		margin-bottom: 5px;
	}
	.cr2_pd_b {
		display: flex;
		flex-direction: column-reverse;
	}
	.cr2_pd_d {
		display: flex;
		padding: 0;
		flex-direction: column;
	}
	.cr2_pd_c {
		height: 60%;
		width: 100%;
	}
	.cr2_pd_e {
		font-size: 0.8rem;
		text-align: center;
		font-weight: 600;
	}
	.cr2_pd_f {
		font-size: 0.7rem;
		/* text-align: center; */
		/* margin-bottom: 5px; */
	}
	.cr2_pd_g {
		margin-left: 1px;
		overflow-x: scroll;
		gap: 0.5rem;
		height: auto;
		width: 100%;
	}
	.cr2_pd_f {
		margin-bottom: 10px;
	}
	.cr2_pd_g img {
		padding: 0;
		margin: 0;
	}
}
